/* menuStyles.css */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
.appBar {
    background-color: #000000 !important;
    border-bottom: 1px solid #CCCCCC !important;
}

.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.search {
    position: relative;
    border-radius: 4px;
    background-color: #333333;
    display: flex;
    align-items: center;
    padding: 0 8px;
    width: 100%;
    max-width: 600px; /* Limita la larghezza massima */
    margin: 0 auto; /* Centra il componente nella pagina */
    box-sizing: border-box; /* Assicura che padding sia incluso nella larghezza */
    overflow: hidden; /* Impedisce l'overflow orizzontale */
}

.search:hover {
    background-color: #555555;
}

.inputInput {
    flex-grow: 1;
    color: #ffffff;
    padding: 8px;
    border: none;
    background: none;
    min-width: 0; /* Impedisce all'input di causare overflow */
}

.iconButton {
    color: #ffffff;
    padding: 8px; /* Aggiungi padding per dare spazio al bottone */
}

/* Media query per migliorare l'aspetto su dispositivi mobili */
@media (max-width: 600px) {
    .search {
        padding: 0 4px;
    }

    .inputInput {
        padding: 4px;
    }
}


.telegramButton {
  color: #FFFFFF; /* Colore del testo e dell'icona */
  border: 1px solid #FFFFFF !important; /* Bordo iniziale */
  transition: all 0.3s ease; /* Transizione fluida per colore e box-shadow */
  border-radius: 5px !important; /* Rimozione dell'effetto circolare */
  width: 35px; /* Imposta la larghezza */
  height: 35px; /* Imposta l'altezza uguale alla larghezza per renderlo quadrato */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0; /* Rimuove il padding */
}

.telegramButton:hover {
  color: #FFFFFF; /* Colore del testo e dell'icona quando hover */
  border: 1px solid #24A1DE !important; /* Bordo blu quando hover */
  box-shadow: 0 0 20px rgba(36, 161, 222, 0.6) !important; /* Box-shadow blu intorno al pulsante */
  background-color: #333333 !important;
}

.coinButton {
  color: #FFFFFF; /* Colore del testo e dell'icona */
  border: 1px solid #FFFFFF !important; /* Bordo iniziale */
  transition: all 0.3s ease; /* Transizione fluida per colore e box-shadow */
  box-shadow: 0 0 20px rgba(255,215,0, 0.6) !important;
  background-color: transparent !important; /* Aggiungi trasparenza iniziale */
  cursor: pointer;
}

.coinButton:hover {
  color: white !important; /* Colore del testo e dell'icona quando hover */
  border: 1px solid rgb(255,215,0) !important; /* Bordo blu quando hover */
  box-shadow: 0 0 20px rgba(255,215,0, 0.6) !important; /* Box-shadow blu intorno al pulsante */
  background-color: #333333 !important;
}

.coinButton .test {
  color: white; /* Colore del testo bianco */
}

.coinButton svg {
  color: yellow; /* Colore dell'icona */
}


/* File: menu.css */

/* Stili per il menu desktop */
.desktopMenu {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

/* Stili per il menu mobile */
.mobileMenu {
  display: none;
}

/* Mostra il menu mobile solo su schermi piccoli */
@media (max-width: 600px) {
  .desktopMenu {
    display: none;
  }
  .mobileMenu {
    display: flex;
    align-items: center;
  }
}
