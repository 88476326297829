body {
  background-color: #000000;
}

.card {
  margin: 16px;
  position: relative;
}

.card img {
  width: 100%;
  display: block;
}

.card-content {
  padding: 16px;
  position: relative; /* Ensure content is above the blur */
  z-index: 1;
}

.card-buttons {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  background-color: rgba(28, 28, 28, 0.8);
}
